import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { urls } from "../../common";
import { createColumnHelper } from "@tanstack/react-table";
import LoadingDots from "../../components/LoadingDots";
import Table from "../../components/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "../../common/dates";
import { toTitleCase } from "../../common/text";
import { declarationStatusColour } from "./common";

const DeclarationSelectionList = (
    { selectedRows, setSelectedRows, id, disabled }:
        { selectedRows: string[], setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>, id?: string, disabled?: string[] }) => {

    type TableProps = {
        id: string,
        name: string,
        toName: string,
        toEmail: string,
        status: string,
        sent_date: number,
        signature_date: number,
        completed_date: number,
        created: number,
        updated: number;
    }

    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState<string>('');
    const [data, setData] = useState<TableProps[]>();

    const fetchURL = `${urls.remoteURL}declarations`;
    const params = {};

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params: { ...params } }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])


    const tableRows = useMemo(() => {
        return isLoading === false && data ? data : [];
    }, [data, selectedRows]);

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('toName', {
            cell: info => <>
                {info.getValue()}
                <span className="block text-sm font-normal">{info.row.original.toEmail}</span>
            </>,
            header: 'Sent To',
            footer: 'Sent To',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('status', {
            cell: info => <span
                className={`${declarationStatusColour(info.getValue())} px-2 py-1 text-white rounded-full`}>{toTitleCase(info.getValue())}</span>,
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),
    ];


    return (
        <div className="overflow-x-auto">
            <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">


                {/* Search */}
                <div className="flex ml-auto" >
                    <label className="form-control-addon-within rounded-full items-center">
                        <input
                            className="form-control border-none expanding"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                        <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                    </label>
                </div>
            </div>
            {isLoading ? <LoadingDots /> :
                <Table
                    defaultData={tableRows}
                    columns={columns}
                    options={{
                        hiddenColumns: ['fullname'],
                        search, selectable: true,
                        hideSelection: true,
                        selectedRows, selectableFunction: setSelectedRows, selectDisabled: disabled,
                        compactFooter: true,
                    }}
                />
            }
        </div>
    );
}

export default DeclarationSelectionList;