import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import { myToast, urls } from "../../../../common";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import ComplianceOptionFilesBulkUploadForm from "./BulkUpload";

export const handleDownloadFile = (fileID: string, filename: string) => {
    axios.get(`${urls.remoteURL}item/option/${fileID}/file`, { responseType: 'blob' })
        .then(({ data, headers }) => {
            const contentType = headers["content-type"];

            if (contentType.startsWith("image/") || contentType === "application/pdf" || contentType.startsWith("text/")) {
                // Preview file in a new tab
                const filePreviewUrl = URL.createObjectURL(data);
                window.open(filePreviewUrl, "_blank");
            } else {
                // Download file
                fileDownload(data, filename || "download");
            }
        });
}



export const handleAddComplianceFile = (complianceID: string, refetch: Function) => {
    const MySwal = withReactContent(Swal)
    let files: File[] | null;

    const handleBulkFilesUpload = (uploadedFiles: File[] | null) => {
        files = uploadedFiles;
    }

    MySwal.fire({
        title: <p>Add Files</p>,
        html: <ComplianceOptionFilesBulkUploadForm inputID="addFileFormJSON" setFiles={handleBulkFilesUpload} />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            MySwal.resetValidationMessage();
            if (!files) {
                MySwal.showValidationMessage(`Please Select a file`)
                return false;
            }

            const data = JSON.parse((document.getElementById("addFileFormJSON") as HTMLInputElement).value);
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`files[]`, file);
            });
            for (const key of Object.keys(data)) { formData.append(key, data[key]); }

            return axios.post(`${urls.remoteURL}item/option/${complianceID}/file`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((x) => {
                return x.data
            }).catch((err) => {
                MySwal.showValidationMessage(err.response.data.message ?? `Problem uploading Documents`)
                return false;
            });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faFileUpload, timing: 3 })
            refetch();
        }
    })
}