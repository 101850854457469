import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { formatDate } from "../../common/dates";
import { toTitleCase } from "../../common/text";
import { handleDeleteIncident } from "./common";
import { PropsLocation } from "../Locations/types";
import DownloadDataButton from "../../components/DownloadDataButton";


const IncidentsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();

    const fetchURL = `${urls.remoteURL}get-incidents`;

    const { isLoading, isError, data, refetch } = useQuery(`company-incidents`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company incidents?' };
    }

    type TableProps = {
        id: string,
        type: string,
        _location: PropsLocation,
        datetime: number,
        updated: number,
        created: number,
    }

    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('type', {
            cell: info => toTitleCase(info.getValue()),
            header: 'Type',
            footer: 'Type',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('_location', {
            cell: info => info.getValue() ? info.getValue().name : 'n/a',
            header: 'Location',
            footer: 'Location',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('datetime', {
            cell: info => formatDate({ time: info.getValue() as number, formatStr: 'HH:mm do MMMM yyyy', empty: 'N/A' }),
            header: 'Date',
            footer: 'Date',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('updated', {
            cell: info => formatDate({ time: info.getValue() as number, formatStr: 'HH:mm do MMMM yyyy', empty: 'N/A' }),
            header: 'Last Updated',
            footer: 'Last Updated',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content="Edit Incident">
                    <Link to={`${info.getValue()}/edit`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                </Tooltip>
                <Tooltip content="Delete Incident">
                    <Button type="button" color="red"
                        onClick={() => { handleDeleteIncident(info.getValue(), refetch) }}
                    ><span className="sr-only">Delete Incident</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                </Tooltip>
            </div>
        )
    }

    const [search, setSearch] = useState<string>();
    useEffect(() => { setSelectedRows([]) }, [data])

    const [downloadData, setDownloadData] = useState<any[]>([])

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            type: row.type,
            location: row._location ? row._location.name : 'n/a',
            'date': formatDate({ time: row.datetime, formatStr: 'HH:mm d/M/yyyy' }),
            'updated': formatDate({ time: row.updated, formatStr: 'HH:mm d/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        <Link to="add" className="btn btn-green uppercase rounded-full px-6">
                            Add New
                        </Link>
                        <DownloadDataButton downloadFilename={`incidents`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{ search, selectable: false, selectedRows, selectableFunction: setSelectedRows }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default IncidentsList;