import React from "react";
import axios from "axios";
import { myToast, urls } from "../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';
import { faBiohazard, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import TrainingRecordAddForm from "./AddForm";


export const TRAININGSTATUS = [
    { id: 'passed', label: 'Passed' },
    { id: 'failed', label: 'Failed' },
    // { id: 'not started', label: 'Not Started' },
]



export const handleAddTrainingRecord = (userID: string, courseID: string, cb?: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        date: yup.number().required(),
        status: yup.string().required(),
    });

    MySwal.fire({
        title: "Add New Training Record",
        html: <TrainingRecordAddForm inputID="addTrainingRecordFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        // width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addTrainingRecordFormJSON") as HTMLInputElement).value);
            console.log(data);

            return schema
                .isValid({
                    date: data.date,
                    status: data.status,
                })
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}user/course`, [{ ...data, userID, courseID }]).then((x) => {
                            return x.data
                        }).catch((err) => {
                            MySwal.showValidationMessage(
                                err.response.data.message ?? `Problem Saving Training Record`
                            )
                            return false;
                        });
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            if (cb) { cb(); }
        }
    })
}


export const handleDeleteTrainingRecord = (userID: string, courseID: string, attempt: number, cb?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}user/course`, { data: { userID, courseID, attempt } })
                .then(() => {
                    myToast({ title: 'Deleted!', message: 'The trainging record has been deleted.', colour: 'green', icon: faThumbsUp, timing: 3 })
                    if (typeof cb === 'function') { cb(); }
                })
                .catch((error) => {
                    myToast({ title: 'Error!', message: error.response.data.message || ' Error deleting trainging record?', colour: 'red', icon: faBiohazard, timing: 3 })
                });

        }
    })
}
