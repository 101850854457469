import React, { useEffect, useState } from "react";
import { urls } from "../../common";

import parse from 'html-react-parser';
import Button from "../../components/Button";
import { formatDate } from "../../common/dates";
import { useCompany } from "../../context/companyProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPaperPlane, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import axios from "axios";
import { PropsDeclaration } from "./types";
import LoadingDots from "../../components/LoadingDots";
import Error from "../Errors";

const ReadOnlyViewDeclaration = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { company } = useCompany();
    const { declarationID } = useParams();
    const [declaration, setDeclaration] = useState<PropsDeclaration>();
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        axios.get(`${urls.remoteURL}declaration/${declarationID}`)
            .then(({ data }) => {
                setDeclaration(data);
            }).catch((response) => {
                setNotFound(true);
            })
    }, []);

    if (notFound) {
        return <Error status={404} message="Declaration not found?" />
    }

    if (!declaration) {
        return <LoadingDots />
    }

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4 space-x-2">
                    <Link
                        className="btn btn-blue px-6 rounded-full"
                        to={'..'}
                    >
                        Back <FontAwesomeIcon icon={faArrowLeft} fixedWidth className="ml-2" />
                    </Link>
                </div>
            </div>
            <div className="max-w-[900px] w-full mx-auto">
                <div className="card p-5 space-y-4">
                    <div className="flex items-center justify-between border-b py-5">
                        <h1 className="flex-1 order-2 md:-order-none lg:text-3xl">{declaration?.name}</h1>
                        <div className="order-1 md:-order-none">
                            {!company?.logo ? <span className="brand relative">{company?.name}</span> : <img src={urls.remoteURL + 'company/' + company.id + '/logo'} className="max-h-20 p-2" />}
                        </div>
                    </div>
                    <div className="pb-4 border-b">
                        {parse(declaration?.content as string)}
                    </div>
                    <div className="space-y-4">
                        <h4>Signature:</h4>
                        <div>
                            <Label>Name</Label>
                            <Input
                                type="text"
                                defaultValue={declaration?.signature_name}
                                disabled
                            />
                        </div>
                        <div>
                            <Label>Position</Label>
                            <Input
                                type="text"
                                defaultValue={declaration?.signature_position}
                                disabled
                            />
                        </div>
                        <div className="border rounded-lg">
                            {declaration?.signature ?
                                <div className="relative">
                                    <img src={declaration.signature} className="rounded-lg p-4" />
                                    <span className="bg-green-600 text-white absolute rounded-full px-2 -right-1 -bottom-1">{formatDate({ time: declaration?.signature_date as number })}</span>
                                </div>
                                :
                                <div className="h-64"></div>
                            }
                        </div>
                    </div>
                    <div>
                        <Button color="blue" disabled className="block w-full text-xl"
                        >{!declaration?.completed_date ? "Sign" : "Signed"}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ReadOnlyViewDeclaration;