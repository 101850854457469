import { useState, createContext, useContext, useEffect } from 'react'
import { PropsPeople } from '../Pages/People/types';
import { PropsCategory } from '../Pages/Categories/types';
import { PropsLocation } from '../Pages/Locations/types';
import { PropsCompliance, PropsComplianceOption } from '../Pages/Compliance/types';
import { PropsIncident } from '../Pages/Incidents/types';
import { tokenID } from '../common';

interface PropsGlobal {
    menuOpen?: boolean
    person?: PropsPeople
    location?: PropsLocation
    compliance?: PropsCompliance
    complianceOption?: PropsComplianceOption
    incident?: PropsIncident
    category?: PropsCategory
    lastDocumentFolder?: string
    elephant?: {
        lastDocumentFolder?: string,
        complianceFilters?: {
            search?: string
            category?: string
            location?: string
            status?: string
        }
    }
}

interface globalContext {
    global: PropsGlobal;
    setGlobal: React.Dispatch<React.SetStateAction<PropsGlobal>>;
}

const GlobalContext = createContext<globalContext>({} as globalContext);

export const GlobalProvider = ({ children }: { children: any }) => {
  
    const settingsKey = tokenID + '-company-settings';
    const sessionSettings = window.localStorage.getItem(settingsKey);

    const [global, setGlobal] = useState<PropsGlobal>(sessionSettings ? JSON.parse(sessionSettings) : { menuOpen: true });

    useEffect(() => {
        window.localStorage.setItem(settingsKey, JSON.stringify(
            {
                menuOpen: global.menuOpen,
                elephant: global.elephant,
            }
        ));
    }, [global])

    return (
        <GlobalContext.Provider value={{ global, setGlobal }}>
            {children}
        </GlobalContext.Provider >
    );
}

export const useGlobal = () => useContext(GlobalContext);