import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faCheckSquare, faEye, faTrashCan, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";

import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { urls } from "../../../../common";

import LoadingDots from "../../../../components/LoadingDots";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import Tooltip from "../../../../components/Tooltip";
import { useCompliance } from "../../../../context/ComplianceProvider";
import { _DaysAway, _now, formatDate } from "../../../../common/dates";
import { handleAddComplianceDeclaration } from "./common";
import { ComplianceOptionButtons, handleArchiveComplianceOption, handleBulkDeleteComplianceOptions, handleDeleteComplianceOption, handleUndoArchiveComplianceOption } from "../../common";
import DownloadDataButton from "../../../../components/DownloadDataButton";
import { PropsDeclaration } from "../../../Declarations/types";
import { PropsCompliance } from "../../types";
import { statusColour, statusDateColour } from "../../../../common/colours";
import { toTitleCase } from "../../../../common/text";
import { Link } from "react-router-dom";
import { declarationStatusColour } from "../../../Declarations/common";

const ComplianceOptionDeclarationsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { compliance } = useCompliance();
    const [archived, setArchived] = useState(false);

    const fetchURL = `${urls.remoteURL}item/options/${compliance?.id}/declarations`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance-${compliance?.id}-declarations`, () => axios.get(fetchURL, { params: { archived } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance declarations?' };
    }

    useEffect(() => {
        if (!isLoading) {
            setSelectedRows([]);
            refetch();
        }
    }, [archived])

    type TableProps = {
        id: string,
        value: {
            declaration_id: string,
            declaration: PropsDeclaration
            // renewalDate: number,
        },
        date_start: number,
        date_expire: number,
        created: number,
        updated: number,
        archived: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('value.declaration.name', {
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('value.declaration.toName', {
            cell: info => <>
                {info.getValue()}
                <span className="block text-sm font-normal">{info.row.original.value.declaration.toEmail}</span>
            </>,
            header: 'Sent To',
            footer: 'Sent To',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('value.declaration.date_expire', {
            cell: info => <span
                className={`${statusDateColour(info.getValue() as number)} px-2 py-1 text-white rounded-full`}>
                {formatDate({ time: info.getValue() as number, formatStr: 'do MMMM yyyy', empty: 'N/A' })}</span>,
            header: 'Expires',
            footer: 'Expires',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('value.declaration.status', {
            cell: info => <span
                className={`${declarationStatusColour(info.getValue() as string)} px-2 py-1 text-white rounded-full`}>{toTitleCase(info.getValue() as string)}</span>,
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {/* <Tooltip content="Edit Renewal">
                    <DatePickerButton
                        id={info.row.original.id}
                        value={fromUnixTime(info.row.original.date_expire)}
                        onChange={(newDate) => {
                            handleUpdateComplianceRenewal(info.row.original.id, getUnixTime(newDate), refetch)
                        }}
                        colour="blue"
                    />
                </Tooltip> */}
                <Tooltip content="View Declaration">
                    <Link
                        className="btn btn-green"
                        to={'../../../declarations/' + info.row.original.value.declaration_id}
                    ><span className="sr-only">View Declaration</span><FontAwesomeIcon icon={faEye} fixedWidth /></Link>
                </Tooltip>
                {archived ?
                    <>
                        <Tooltip content="Undo Declaration">
                            <Button type="button" color="indigo"
                                onClick={() => handleUndoArchiveComplianceOption(info.getValue(), 'Declaration', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Undo Declaration</span><FontAwesomeIcon icon={faTrashUndo} fixedWidth /></Button>
                        </Tooltip>
                        <Tooltip content="Delete Declaration">
                            <Button type="button" color="red"
                                onClick={() => handleDeleteComplianceOption(info.getValue(), 'Declaration', () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Delete Declaration</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    </>
                    :
                    <Tooltip content="Archive Declaration">
                        <Button type="button" color="red"
                            onClick={() => handleArchiveComplianceOption(info.getValue(), 'Declaration', () => { setSelectedRows([]); refetch(); })}
                        ><span className="sr-only">Archive Declaration</span><FontAwesomeIcon icon={faBoxArchive} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }

    const [downloadData, setDownloadData] = useState<any[] | undefined>()
    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            name: row.value.declaration.name,
            signed: row.value.declaration.signature_date as number > 0 ? formatDate({ time: row.value.declaration.signature_date as number, formatStr: 'd/M/yyyy' }) : 'n/a',
            start: formatDate({ time: row.date_start, formatStr: 'd/M/yyyy' }),
            expire: formatDate({ time: row.date_expire, formatStr: 'd/M/yyyy' }),
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }));
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-5 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}



                <div className="space-y-2 text-right mb-5">
                    <div className="flex gap-x-2 justify-end">
                        {/* Add New */}
                        <Button color="green" className=" uppercase rounded-full px-6"
                            onClick={() => { handleAddComplianceDeclaration(compliance as PropsCompliance, refetch) }}
                        >
                            Add New
                        </Button>
                        <Button
                            onClick={() => { setArchived(!archived) }}
                            color={!archived ? 'gray' : 'rhinoBlue'}
                            className={`uppercase rounded-full px-6 space-x-2`}
                        >
                            <FontAwesomeIcon icon={!archived ? faSquare : faCheckSquare} />
                            <span>Archived</span>
                        </Button>
                        <DownloadDataButton downloadFilename={`${compliance?.name}-declarations`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                    {compliance?.options && <ComplianceOptionButtons options={compliance?.options as string[]} />}
                </div>

            </div >
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                selectable: true, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => archived ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeleteComplianceOptions(selectedRows as string[], 'Renewals', () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button>
                                    : <></>
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceOptionDeclarationsList;