import React from 'react';
import Error from '../Errors';

import {
    Routes,
    Route,
    useParams, Navigate
} from 'react-router-dom';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import { ComplianceProvider } from '../../context/ComplianceProvider';
import ComplianceForm from './Form';
import ComplianceOptionRenewalList from './Options/Renewal/List';
import ComplianceOptionDocumentList from './Options/Files/List';
import ComplianceOptionCertificateList from './Options/Certificates/List';
import ComplianceOptionCourseList from './Options/Courses/List';
import ComplianceOptionPeopleList from './Options/People/List';
import ComplianceOptionAssetsList from './Options/Assets/List';
import ComplianceOptionAssetsForm from './Options/Assets/Form';
import ComplianceOptionBulletinList from './Options/Bulletin/List';
import ComplianceOptionLogsList from './Options/Logs/List';
import ComplianceOptionCertificateForm from './Options/Certificates/Form';
import ComplianceOptionNotesList from './Options/Notes/List';
import ComplianceOptionNotesForm from './Options/Notes/Form';
import { useCompany } from '../../context/companyProvider';
import RequireAuthPage from '../../common/RequireAuthPage';
import ComplianceOptionDeclarationsList from './Options/Declarations/List';


function CompliancePage() {
    let { complianceID } = useParams();
    const { company } = useCompany();


    return (
        <>
            <ComplianceProvider id={complianceID as string}>
                <Routes>
                    <Route errorElement={<Error fullscreen={false} />}  >

                        <Route index element={<Navigate to={'edit'} replace />} />
                        <Route path='edit' element={<RequireAuthPage permissions='edit_items' redirect='../../'><ComplianceForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path='notes' element={<ComplianceOptionNotesList MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='notes/:optionID' element={<RequireAuthPage permissions='edit_items' redirect='../../'><ComplianceOptionNotesForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path='renewal' element={<ComplianceOptionRenewalList MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path='documents' element={<ComplianceOptionDocumentList MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path='certificates' element={<ComplianceOptionCertificateList MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='certificates/:optionID' element={<RequireAuthPage permissions='edit_items' redirect='../../'><ComplianceOptionCertificateForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path='courses' element={<ComplianceOptionCourseList MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='people' element={<ComplianceOptionPeopleList MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path='assets' element={<ComplianceOptionAssetsList MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='assets/:optionID' element={<RequireAuthPage permissions='edit_items' redirect='../../'><ComplianceOptionAssetsForm MenuElement={<CompanyBreadcrumbs />} /></RequireAuthPage>} />

                        <Route path='declarations' element={<ComplianceOptionDeclarationsList MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path='bulletin' element={<ComplianceOptionBulletinList MenuElement={<CompanyBreadcrumbs />} />} />
                        <Route path='logs' element={<ComplianceOptionLogsList MenuElement={<CompanyBreadcrumbs />} />} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found?'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </ComplianceProvider >
        </>
    );
}

export default CompliancePage;