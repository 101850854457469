import React from "react";
import axios from "axios";

import { myToast, urls } from "../../../../common";

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

import * as yup from 'yup';
import ComplianceOptionDeclarationAddForm from "./AddForm";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { PropsCompliance } from "../../types";


export const handleAddComplianceDeclaration = (compliance: PropsCompliance, refetch: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        declarations: yup.array().min(1, 'Please Choose at least one declaration'),
    });

    MySwal.fire({
        title: "Add Declaration",
        html: <ComplianceOptionDeclarationAddForm compliance={compliance} inputID="addDeclarationFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 1000,
        customClass: {
            popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addDeclarationFormJSON") as HTMLInputElement).value);

            return schema
                .validate(data)
                .then((valid) => {
                    // if (valid) {
                    return axios.post(`${urls.remoteURL}item/option/${compliance.id}/declaration`, data).then((x) => {
                        return true;
                    }).catch((err) => {
                        MySwal.showValidationMessage(
                            err.response.data.message ?? `Problem adding Declaration`
                        )
                        return false;
                    });
                    // } else {
                }).catch(({ errors }) => {
                    MySwal.showValidationMessage(errors ? errors[0] : `Fill in all required fields!`)
                    return false;
                    // }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}