import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { ITEMTYPES, STATUS, urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faBroomWide, faCheck, faEdit, faEnvelope, faInfo, faPaperPlane, faSearch, faTrashCan, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { PropsCategory } from "../Categories/types";
import { statusColour, statusDateColour } from "../../common/colours";
import { formatDate } from "../../common/dates";
import { handleArchiveCompliance, handleComplianceSendBulletinEmail, handleComplianceSendEmail, handleDeleteCompliance, handleUndoArchiveCompliance } from "./common";
import { useCompany } from "../../context/companyProvider";
import { CategoryDropdownSelect } from "../Categories/DropdownList";
import { LocationDropdownSelect } from "../Locations/DropdownList";
import CustomSelect from "../../components/form/CustomSelect";
import { useGlobal } from "../../context/globals";
import DownloadDataButton from "../../components/DownloadDataButton";


const ComplianceList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const location = useLocation();
    const { company } = useCompany();
    const { global, setGlobal } = useGlobal();

    const passedVariables: { location?: string, category?: string, status?: string } = location.state || {};


    const [selectedRows, setSelectedRows] = useState<string[]>();
    const [categoryID, setCategoryID] = useState<string>(passedVariables.category || global.elephant?.complianceFilters?.category || '');
    const [locationID, setLocationID] = useState<string>(passedVariables.location || global.elephant?.complianceFilters?.location || '');
    const [status, setStatus] = useState<string>(passedVariables.status || global.elephant?.complianceFilters?.status || '');

    const [archived, setArchived] = useState(false);

    const [filteredRows, setFilteredRows] = useState<any>()

    const fetchURL = `${urls.remoteURL}get-items`;

    const { isLoading, isError, data, refetch } = useQuery(`company-compliance`, () => axios.get(fetchURL, { params: { categoryID, locationID, archived } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company compliance?' };
    }



    type TableProps = {
        id: string,
        name: string,
        category: PropsCategory,
        site: { name: string }
        renewal: { date_expire: number }
        status: {
            status: string,
            renewal: { time: number, status: string },
            certificates: { status: string, count: number, failed: number, upcoming: number, passed: number, notes: string },
            assets: { status: string, count: number, failed: number, upcoming: number, passed: number, notes: string },
            notes: string,
        },
        type: string,
        list_order: number,
        options: string[],
        notifications: boolean,
        archived: boolean,
        created: number,
        updated: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('category', {
            cell: info => info.getValue()?.name || 'n/a',
            header: 'Category',
            footer: 'Category',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('site.name', {
            cell: info => info.getValue() ? info.getValue() : 'n/a',
            header: 'Location',
            footer: 'Location',
            sortingFn: 'alphanumeric',
        }),
        columnHelper.accessor('status.renewal.time', {
            cell: info => <span
                className={`${statusDateColour(info.getValue())} px-2 py-1 text-white rounded-full`}>{info.getValue() > 0 ? formatDate({ time: info.getValue() }) : 'n/a'}</span>,
            header: 'Renewal',
            footer: 'Renewal',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('status', {
            cell: info => rowStatus(info.row.original),
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('options', {
            header: 'Options',
            footer: 'Options',
            cell: info => optionsCell(info.row.original),
            enableSorting: false,
            enableGlobalFilter: false,
        }),


        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const optionsCell = (options: TableProps) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {ITEMTYPES.filter(x => x.permanent || options.options.includes(x.id)).map(type => {
                    return (
                        <Tooltip content={`${type.label}`}>
                            <Link to={`${options.id}/${type.link}`} className={`btn ${type.buttonColour}`}><span className="sr-only">{type.label}</span><FontAwesomeIcon icon={type.icon} fixedWidth /></Link>
                        </Tooltip>
                    )
                })}
            </div>
        )
    }


    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {!!company.edit_items &&
                    <Tooltip content="Edit Compliance">
                        <Link to={`${info.getValue()}/edit`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                    </Tooltip>
                }

                <Tooltip content="Send Email">
                    <Button
                        type="button" color="rhinoBlue"
                        disabled={!info.row.original.notifications}
                        onClick={() => { handleComplianceSendEmail(info.getValue(), refetch) }}
                    ><span className="sr-only">Send Email</span><FontAwesomeIcon icon={faEnvelope} fixedWidth /></Button>
                </Tooltip>

                {info.row.original.options.includes('BULLETIN') && info.row.original.options.includes('PEOPLE') &&
                    <Tooltip content="Send Bulletin">
                        <Button
                            type="button" color="orange"
                            onClick={() => { handleComplianceSendBulletinEmail(info.getValue(), refetch) }}
                        ><span className="sr-only">Send Bulletin</span><FontAwesomeIcon icon={faPaperPlane} fixedWidth /></Button>
                    </Tooltip>
                }

                {!!company.edit_items &&
                    info.row.original.archived ?
                    <>
                        <Tooltip content="Undo Archive">
                            <Button type="button" color="indigo"
                                onClick={() => handleUndoArchiveCompliance(info.getValue(), () => { setSelectedRows([]); refetch(); })}
                            ><span className="sr-only">Undo Archive</span><FontAwesomeIcon icon={faTrashUndo} fixedWidth /></Button>
                        </Tooltip>
                        <Tooltip content="Delete Compliance">
                            <Button
                                type="button" color="red"
                                onClick={() => { handleDeleteCompliance(info.getValue(), refetch) }}
                            ><span className="sr-only">Delete Compliance</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    </>
                    :
                    <Tooltip content="Archive Compliance">
                        <Button
                            type="button" color="red"
                            onClick={() => { handleArchiveCompliance(info.getValue(), refetch) }}
                        ><span className="sr-only">Archive Compliance</span><FontAwesomeIcon icon={faBoxArchive} fixedWidth /></Button>
                    </Tooltip>

                }
            </div>
        )
    }

    type PropsRowStatus = Pick<TableProps, 'status'>;
    const rowStatus = (row: PropsRowStatus) => {
        // if (!row.status.certificates && !row.status.assets) {
        return (
            <>
                {
                    row.status.status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                        <Tooltip content={row.status.notes}>
                            <span className={
                                `${statusColour(row.status.status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                        </Tooltip>
                }
            </>
        )
        // }

        if (row.status.certificates) {
            return (
                <>
                    {
                        row.status.certificates.status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                            <Tooltip content={row.status.certificates.notes}>
                                <span className={
                                    `${statusColour(row.status.certificates.status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                            </Tooltip>
                    }
                </>
            )
        }

        if (row.status.assets) {
            return (
                <>
                    {
                        row.status.assets.status === 'passed' ? <span className={`bg-green-500 px-2 py-2 text-white rounded-full`} ><FontAwesomeIcon icon={faCheck} fixedWidth /></span> :
                            <Tooltip content={row.status.assets.notes}>
                                <span className={
                                    `${statusColour(row.status.assets.status)} px-2 py-2 text-white rounded-full cursor-pointer`} ><FontAwesomeIcon icon={faInfo} fixedWidth /></span>
                            </Tooltip>
                    }
                </>
            )
        }

    }

    const [search, setSearch] = useState<string>(global.elephant?.complianceFilters?.search || '');


    useEffect(() => {
        setGlobal(prev => ({
            ...prev,
            elephant: {
                ...prev.elephant,
                complianceFilters: {
                    search,
                    category: categoryID,
                    location: locationID,
                    status,
                }
            }
        }))
    }, [search, categoryID, locationID, status])

    const clearFilters = () => {
        setCategoryID('');
        setLocationID('');
        setStatus('');
        setSearch('');
    }

    useEffect(() => { refetch(); }, [categoryID, locationID, archived])

    useEffect(() => {
        if (status === 'archived') {
            setArchived(true);
        } else {
            setArchived(false);
        }
        if (data?.data) {
            setFilteredRows(data.data.filter((x: { status: { status: string } }) => {
                if (!status || status === 'archived') return true;
                return status === x.status.status
            }))
        }
    }, [data, status])


    const [downloadData, setDownloadData] = useState<any[]>([])

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            compliance: row.name,
            category: row.category ? row.category.name : 'n/a',
            location: row.site ? row.site.name : 'n/a',
            renewal: row.renewal?.date_expire ? formatDate({ time: row.renewal.date_expire, formatStr: 'd/M/yyyy' }) : 'n/a',
            notes: row.status.notes,
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])




    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <CategoryDropdownSelect onChange={setCategoryID} value={categoryID} />
                    <LocationDropdownSelect onChange={setLocationID} value={locationID} nested />

                    <CustomSelect value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">{!status ? `Select Status` : `Clear Status`}</option>
                        {STATUS.map(x => <option value={x.id}>{x.name}</option>)}
                        <option value="archived">Archived</option>
                    </CustomSelect>

                    <div className="flex gap-x-2">
                        <Tooltip content="Clear Filters">
                            <Button
                                className="rounded-full px-3" color="red"
                                onClick={clearFilters}
                            ><span className="sr-only">Clear</span><FontAwesomeIcon icon={faBroomWide} /></Button>
                        </Tooltip>
                        {/* Add New */}
                        {!!company.edit_items &&
                            <Link to="add" className="btn btn-green uppercase rounded-full px-6">
                                Add New
                            </Link>
                        }
                        <DownloadDataButton downloadFilename={`compliance-items`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={filteredRows}
                            columns={columns}
                            options={{ search, selectable: true, selectedRows, selectableFunction: setSelectedRows }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplianceList;