import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { USERFILTER, urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEdit, faEye, faLayerGroup, faSearch, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import Dropdown from "../../components/Dropdown";
import { handleAddPerson, handleBulkAddPeople, handleBulkDeletePeople, handleDeletePerson } from "./common";
import { useCompany } from "../../context/companyProvider";
import { formatDate } from "../../common/dates";
import DownloadDataButton from "../../components/DownloadDataButton";
import { useUser } from "../../context/userProvider";


const PeopleList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { user } = useUser();
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const [filter, setFilter] = useState(USERFILTER[0]);
    const { company } = useCompany();

    const fetchURL = `${urls.remoteURL}get-people`

    const { isLoading, isError, data, refetch } = useQuery(`company-people`, () => axios.get(fetchURL, { params: { filter: [filter.type] } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company people?' };
    }

    useEffect(() => {
        refetch();
    }, [filter])

    type TableProps = {
        id: string,
        firstname: string,
        lastname: string,
        fullname: string,
        email: string,
        type: string,
        created: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('fullname', {}),
        columnHelper.accessor('lastname', {
            cell: info => <>
                {info.row.original.firstname + ' ' + info.row.original.lastname}
                <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span>
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('email', {
            cell: info => info.getValue(),
            header: 'Email',
            footer: 'Email',
            meta: {
                className: 'hidden md:table-cell'
            }
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                {!!company?.edit_people ?
                    <Tooltip content="Edit Person">
                        <Link to={`${info.getValue()}/edit`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                    </Tooltip>
                    :
                    <Tooltip content="View Person">
                        <Link to={`${info.getValue()}`} className="btn btn-green"><span className="sr-only">View</span><FontAwesomeIcon icon={faEye} fixedWidth /></Link>
                    </Tooltip>
                }
                {!!company?.view_sites &&
                    <Tooltip content="Locations">
                        <Link to={`${info.getValue()}/locations`} className="btn btn-orange"><span className="sr-only">Locations</span><FontAwesomeIcon icon={faLayerGroup} fixedWidth /></Link>
                    </Tooltip>
                }
                {!!company?.edit_people &&
                    <Tooltip content="Delete Person">
                        <Button type="button" color="red"
                            disabled={info.getValue() === user?.id}
                            onClick={() => handleDeletePerson(info.getValue(), refetch)}
                        ><span className="sr-only">Delete Person</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                    </Tooltip>
                }
            </div>
        )
    }

    const [search, setSearch] = useState<string>();

    const [downloadData, setDownloadData] = useState<any[]>([])

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            firstname: row.firstname,
            lastname: row.lastname,
            email: row.email,
            type: USERFILTER.find(x => x.id === row.type)?.name || 'Person',
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])




    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4 cursor-pointer"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>


                    {/* Filter */}
                    <Dropdown
                        content={
                            <div className="dropdown-menu">
                                {USERFILTER.map((type) => (
                                    <button key={type.type} onClick={() => setFilter(type)}>
                                        <FontAwesomeIcon icon={type.icon} fixedWidth className="mr-2" />
                                        {type.name}
                                    </button>
                                ))}
                            </div>
                        }
                    >
                        <Button color="rhinoLightBlue" className="uppercase pl-4 rounded-full">
                            <FontAwesomeIcon icon={filter.icon} fixedWidth className="mr-2" />
                            {filter.name}
                            <span className="ml-2 text-xl leading-none"><FontAwesomeIcon icon={faCaretDown} fixedWidth /></span>
                        </Button>
                    </Dropdown>


                    <div className="flex gap-x-2">
                        {!!company.edit_people &&
                            <>
                                <Button color="rhinoBlue" className=" uppercase rounded-full px-6" onClick={() => { handleBulkAddPeople(refetch) }}>
                                    Bulk Upload
                                </Button>
                                <Button color="green" className=" uppercase rounded-full px-6" onClick={() => { handleAddPerson(refetch) }}>
                                    Add New
                                </Button>
                            </>
                        }
                        <DownloadDataButton downloadFilename={`all-people`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search, selectable: true, selectedRows, selectableFunction: setSelectedRows,
                                BulkButtons: () => !!company.edit_people ? <Button color="red"
                                    onClick={() => {
                                        handleBulkDeletePeople(selectedRows as string[], () => { setSelectedRows([]); refetch(); })
                                    }} >DELETE</Button> : null,
                                hiddenColumns: ['fullname']
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default PeopleList;