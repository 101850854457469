import React, { useEffect, useState } from "react";

import DeclarationSelectionList from "../../../Declarations/SelectionList";
import { PropsCompliance } from "../../types";

type formProps = {
    declarations: string[]
    // dateStart?: number, dateExpire?: number
};

const ComplianceOptionDeclarationAddForm = ({ compliance, inputID }: { compliance: PropsCompliance, inputID: string, }) => {
    const [json, setJSON] = useState<formProps>({ declarations: [] });
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    useEffect(() => {
        setJSON({ declarations: selectedRows });
    }, [selectedRows])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <DeclarationSelectionList
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                disabled={compliance?.item_options?.filter(x => x.element === 'DECLARATION').map(declaration => declaration.value.declaration_id) as string[]}
            />

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default ComplianceOptionDeclarationAddForm;