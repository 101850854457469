import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChildren, faEdit, faSearch, faTrashCan, faUserTieHair, faUsers } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { handleAddLocation, handleDeleteLocation } from "./common";
import { useCompany } from "../../context/companyProvider";
import { formatDate } from "../../common/dates";
import DownloadDataButton from "../../components/DownloadDataButton";


const LocationsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const { company } = useCompany();

    const fetchURL = `${urls.remoteURL}get-sites`;

    const { isLoading, isError, data, refetch } = useQuery(`company-sites`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting company locations?' };
    }


    type TableProps = {
        id: string,
        name: string,
        site_order: number,
        children: number,
        admins: number,
        members: number,
        town: string,
        postcode: string,
        created: number,

    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('children', {
            cell: info => info.getValue(),
            header: 'Sub Locations',
            footer: 'Sub Locations',
            meta: {
                className: 'hidden md:table-cell'
            }
        }),

        columnHelper.accessor('admins', {
            cell: info => info.getValue(),
            header: 'Managers',
            footer: 'Managers',
            meta: {
                className: 'hidden md:table-cell'
            }
        }),

        columnHelper.accessor('members', {
            cell: info => info.getValue(),
            header: 'Members',
            footer: 'Members',
            meta: {
                className: 'hidden md:table-cell'
            }
        }),

        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <>
                    {!!company.edit_sites &&
                        <Tooltip content="Edit Location">
                            <Link to={`${info.getValue()}/edit`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                        </Tooltip>
                    }
                    <Tooltip content="View Sub Location">
                        <Link to={`${info.getValue()}/children`} className="btn btn-orange"><span className="sr-only">View Sub Location</span><FontAwesomeIcon icon={faChildren} fixedWidth /></Link>
                    </Tooltip>
                    <Tooltip content="View Managers">
                        <Link to={`${info.getValue()}/managers`} className="btn btn-pink"><span className="sr-only">View Managers</span><FontAwesomeIcon icon={faUserTieHair} fixedWidth /></Link>
                    </Tooltip>
                    <Tooltip content="View People">
                        <Link to={`${info.getValue()}/people`} className="btn btn-violet"><span className="sr-only">View People</span><FontAwesomeIcon icon={faUsers} fixedWidth /></Link>
                    </Tooltip>
                    {!!company.edit_sites &&
                        <Tooltip content="Delete Location">
                            <Button type="button" color="red"
                                disabled={info.row.original.children > 0}
                                onClick={() => handleDeleteLocation(info.getValue(), refetch)}
                            ><span className="sr-only">Delete Location</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                        </Tooltip>
                    }
                </>
            </div>
        )
    }

    const [search, setSearch] = useState<string>();
    const [downloadData, setDownloadData] = useState<any[]>([])

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            name: row.name,
            admin: row.admins,
            members: row.members,
            town: row.town || 'n/a',
            postcode: row.postcode || 'n/a',
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])



    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        {/* Add New */}
                        {!!company.edit_sites &&
                            <Button type="button" onClick={() => { handleAddLocation(undefined, refetch) }} className="btn btn-green uppercase rounded-full px-6">
                                Add New
                            </Button>
                        }
                        <DownloadDataButton downloadFilename={`locations`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />

                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search, selectable: !!company.edit_categories, selectedRows, selectableFunction: setSelectedRows,
                                // BulkButtons: () => <Button color="red"
                                //     onClick={() => {
                                //         handleBulkDeleteLocations(selectedRows as string[], () => { setSelectedRows([]); refetch(); })
                                //     }} >DELETE</Button>,

                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default LocationsList;