import React, { useEffect, useState } from "react";
import axios from "axios";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";

import { urls } from "../../common";
import LoadingDots from "../../components/LoadingDots";
import CustomSelect from "../../components/form/CustomSelect";

type PropsList = {
    value: string,
    label: string,
    children: PropsList[]
}


const LocationDropdownSelectControl = (props: UseControllerProps<FieldValues, string> & { nested?: boolean }) => {
    const { nested } = props;
    const {
        field: { value, onChange }
    } = useController(props);

    const fetchURL = `${urls.remoteURL}get-sites`;
    const params = { type: 'select', nested: true, showAll: true };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    const buildNestedOptions = (item: PropsList) => {
        if (item.children.length === 0) {
            return (<option key={`location-option-${item.value}`} value={item.value}>{item.label}</option>);
        }
        return (
            <optgroup key={'group_' + item.value} label={item.label}>
                <option key={'location_' + item.value} value={item.value}>{item.label}</option>
                {item.children.map(buildNestedOptions)}
            </optgroup>
        )
    }

    if (nested) {
        return (
            <CustomSelect
                defaultValue={value} onChange={onChange}
            >
                <option value="0">Select location</option>
                {data?.map(buildNestedOptions)}

            </CustomSelect>

        )
    }

    return (
        <CustomSelect
            defaultValue={value} onChange={onChange}
        >
            <option>Select location</option>
            {data?.map(x => <option key={`location-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}

export default LocationDropdownSelectControl;


export const LocationDropdownSelect = ({  value, onChange, nested }: { value: string, onChange: React.Dispatch<React.SetStateAction<string>>, nested?: boolean }) => {

    const fetchURL = `${urls.remoteURL}get-sites`;
    const params = { type: 'select', nested: true, showAll: true };

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<PropsList[]>();

    useEffect(() => {
        if (isLoading) {
            axios.get(fetchURL, { params }).then(({ data }) => {
                setData(data);
                setIsLoading(false)
            });
        }
    }, [isLoading])

    if (isLoading) {
        return (
            <LoadingDots />
        )
    }

    const buildNestedOptions = (item: PropsList) => {
        if (item.children.length === 0) {
            return (<option key={`location-option-${item.value}`} value={item.value}>{item.label}</option>);
        }
        return (
            <optgroup key={'group_' + item.value} label={item.label}>
                <option key={'location_' + item.value} value={item.value}>{item.label}</option>
                {item.children.map(buildNestedOptions)}
            </optgroup>
        )
    }

    if (nested) {
        return (
            <CustomSelect
                value={value} onChange={(e) => onChange(e.target.value)}
            >
                <option value="0">{!value ? `Select Location` : 'Clear Location'}</option>
                {data?.map(buildNestedOptions)}

            </CustomSelect>

        )
    }

    return (
        <CustomSelect
            defaultValue={value} onChange={(e) => onChange(e.target.value)}
        >
            <option>{!value ? `Select Location` : 'Clear Location'}</option>
            {data?.map(x => <option key={`location-option-${x.value}`} value={x.value}>{x.label}</option>)}
        </CustomSelect>
    )
}