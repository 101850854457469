import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import {
    ChonkyActions,
    ChonkyFileActionData,
    ChonkyIconName,
    ChonkyIconProps,
    defineFileAction,
    FileArray,
    FileBrowser,
    FileBrowserHandle,
    FileContextMenu,
    FileData,
    FileHelper,
    FileList,
    FileNavbar,
    FileToolbar,
    FullFileBrowser,
    setChonkyDefaults,

} from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';

import { myToast, urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../context/globals";
import { handleAddDocument, handleAddDocumentFolder, handleBulkDeleteDocuments, handleDeleteDocumentFolder, handleDownloadFile, handleEditDocumentFolder } from "./common";
import { faBan, faQuestionCircle, faTrashAltSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PropsFileMap = {
    [fileId: string]: FileData & { childrenIds: string[] };
}



const DocumentFileBrowser = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const { global, setGlobal } = useGlobal();
    const [currentFolderID, setCurrentFolderID] = useState<string>(global.lastDocumentFolder || 'folder-root');
    const [fileMap, setFileMap] = useState<PropsFileMap>();
    const navigate = useNavigate();

    setChonkyDefaults({
        iconComponent: ChonkyIconFA as any,
        // disableSelection: true,
        disableDragAndDrop: true,
    });

    const fetchURL = `${urls.remoteURL}get-file-browser`;

    const { isLoading, isError, data, refetch } = useQuery(`documents`, () => axios.get(fetchURL, {}));

    if (isError) {
        throw { code: 500, message: 'Error getting documents?' };
    }

    const Spacer = defineFileAction({
        id: 'folder_space',
        requiresSelection: true,
        button: {
            name: '---------',
            contextMenu: true,
            group: 'Folder',
        },
    } as const);

    const DeleteFolder = defineFileAction({
        id: 'delete_folder',
        requiresSelection: true,
        button: {
            name: 'Delete Folder',
            contextMenu: true,
            group: 'Folder',
            icon: 'trash'
        },
    } as const);

    const EditFolder = defineFileAction({
        id: 'edit_folder',
        requiresSelection: true,
        button: {
            name: 'Edit Folder',
            contextMenu: true,
            group: 'Folder',
            icon: 'folder'
        },
    } as const);

    const [showFolderActions, setShowFolderActions] = useState(true);

    const myFileActions: any[] = [
        ChonkyActions.UploadFiles,
        ChonkyActions.CreateFolder,
        {
            ...ChonkyActions.DeleteFiles,
            fileFilter: (file: PropsFileMap) => !file.isDir && !file.readOnly,
        },
        ChonkyActions.DownloadFiles,
        { ...Spacer, fileFilter: () => false },
        {
            ...EditFolder,
            fileFilter: (file: PropsFileMap) => file.isDir && showFolderActions && !file.editable

        },
        {
            ...DeleteFolder,
            fileFilter: (file: PropsFileMap) => file.isDir && !file.childrenCount && !file.locked && showFolderActions

        },
    ];

    const actionsToDisable: string[] = [
        // ChonkyActions.EnableListView.id,
        ChonkyActions.SelectAllFiles.id,
        ChonkyActions.OpenSelection.id,
        ChonkyActions.ToggleHiddenFiles.id,
    ];


    useEffect(() => {
        if (!isLoading && data?.data) {
            setFileMap(data.data);
            // console.log(data.data)
        }
    }, [data])

    useEffect(() => {
        setGlobal(prev => ({ ...prev, lastDocumentFolder: currentFolderID }))
    }, [
        currentFolderID
    ])

    const files = useMemo(() => {
        if (fileMap) {
            const currentFolder = fileMap[currentFolderID];
            const files: FileArray = currentFolder?.childrenIds
                ? currentFolder.childrenIds.map((fileId: string) => fileMap[fileId] ?? null)
                : [];
            return files;
        }
    }, [currentFolderID, fileMap]);

    const folderChain = useMemo(() => {
        if (fileMap) {
            const currentFolder = fileMap[currentFolderID];

            const folderChain = [currentFolder];

            let parentId = currentFolder?.parentId;
            while (parentId) {
                const parentFile = fileMap[parentId];
                if (parentFile) {
                    folderChain.unshift(parentFile);
                    parentId = parentFile.parentId;
                } else {
                    parentId = undefined;
                }
            }

            return folderChain;
        }
    }, [currentFolderID, fileMap]);


    const handleFileAction = useCallback(
        (data: ChonkyFileActionData) => {
            // if (data) {
            //     const selectedFiles = data.state.selectedFiles;
            //     let isDir = false;
            //     for (const item of selectedFiles) {
            //         isDir = isDir ? isDir : !!item.isDir;
            //     }

            //     if (isDir) {
            //         setMyFileActions([
            //             ChonkyActions.UploadFiles,
            //             ChonkyActions.CreateFolder,
            //             // ChonkyActions.DeleteFiles,
            //             ChonkyActions.DownloadFiles,
            //         ])
            //     } else {
            //         setMyFileActions([
            //             ChonkyActions.UploadFiles,
            //             ChonkyActions.CreateFolder,
            //             ChonkyActions.DeleteFiles,
            //             ChonkyActions.DownloadFiles,
            //         ])
            //     }
            // }

            if (data.id === ChonkyActions.OpenFiles.id) {
                const { targetFile, files } = data.payload;
                const fileToOpen = targetFile ?? files[0];
                if (fileToOpen.readOnly) {
                    myToast({ title: 'Read Only', colour: 'red', icon: faBan, timing: 3 })
                    return;
                }
                if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                    setCurrentFolderID(fileToOpen.id);
                    return;
                } else {
                    // console.log(targetFile?.id.split('-')[1]);
                    navigate(`./${targetFile?.id.split('-')[1]}`);
                }
            } else if (data.id === 'upload_files') {
                handleAddDocument({ cp: refetch, folderID: currentFolderID.split('-')[1] });
            } else if (data.id === 'delete_files') {
                const ids = data.state.selectedFiles.filter(x => !x.isDir).map(x => x.id.split('-')[1]);


                if (data.state.selectedFiles.filter(x => x.readOnly).length > 0) {
                    myToast({ title: 'Read Only', message: 'Please deselect read only files.', colour: 'red', icon: faBan, timing: 3 })
                    return;
                }

                if (data.state.selectedFiles.filter(x => x.isDir).length > 0) {
                    myToast({ title: 'Folder Selected', message: 'Please deselect folders. ', colour: 'red', icon: faBan, timing: 3 })
                    return;
                }

                if (ids.length > 0) {
                    handleBulkDeleteDocuments(ids, refetch);
                } else {
                    myToast({ title: 'No Files selected?', icon: faQuestionCircle, colour: 'orange', timing: 3 })
                }
            } else if (data.id === 'create_folder') {
                handleAddDocumentFolder({ folderID: currentFolderID.split('-')[1], cp: refetch });
            } else if (data.id as string === 'edit_folder') {
                const folderID = data.state.selectedFiles[0].id.split('-')[1];
                const currentName = data.state.selectedFiles[0].name;
                handleEditDocumentFolder({ folderID, cp: refetch, currentName });
            } else if (data.id as string === 'delete_folder') {
                const folderID = data.state.selectedFiles[0].id.split('-')[1];
                handleDeleteDocumentFolder({ folderID, cp: refetch });
            } else if (data.id === 'download_files') {
                const downloading = data.state.selectedFiles as any;
                const download = downloading.filter((x: PropsFileMap) => x.id.startsWith('file-')).map((x: PropsFileMap) => ({ ...x, id: x.id.split('-')[1] }));
                const folders = downloading.filter((x: PropsFileMap) => x.id.startsWith('folder-')).map((x: PropsFileMap) => ({ ...x, id: x.id.split('-')[1] }));


                const recursiveFiles = (folder: any) => {
                    if (folder.childrenCount && folder.childrenCount > 0 && fileMap) {
                        for (const child of folder.childrenIds) {
                            if (child.startsWith('file-')) {
                                download.push({ ...fileMap[child], id: child.split('-')[1] });
                            } else {
                                recursiveFiles(fileMap[child]);
                            }
                        }
                    }
                }

                if (folders.length > 0 && fileMap) {
                    for (const folder of folders) {
                        recursiveFiles(folder);
                    }
                }

                if (download.length === 1) {
                    handleDownloadFile({ documentID: download[0].id, filename: download[0].name })
                } else if (download.length > 1) {
                    handleDownloadFile({ documentID: download.map((x: PropsFileMap) => x.id), filename: `export.zip` })
                }
            } else if (data.id === 'change_selection') {
                setShowFolderActions(data.state.selectedFiles.length === 1);
            } else {
                // console.log(data);
            }

            // showActionNotification(data);
        },
        [currentFolderID]);


    const MyIcon: React.FC<ChonkyIconProps> = React.memo((props) => {
        const options = props.icon.split('-');
        // console.log(props)
        if (options.length > 1) {
            // props.icon = null;
            return <div className="relative ">
                {/* <FontAwesomeIcon icn={faFile} /> */}
                <ChonkyIconFA {...props} icon={options[0]} />
                <FontAwesomeIcon icon={faTrashAltSlash} className="readOnly-icon h-4 w-4 absolute top-0 -right-4 text-red-700" />

            </div>;
        }
        return <ChonkyIconFA {...props} />;
    });

    return (
        <>
            <div className="lg:flex items-start w-full" >
                {MenuElement}
            </div>

            <div className="grid">

                <div className="card p-5">
                    {!files ? <LoadingDots /> :
                        <div className="h-[80vh]">
                            <FileBrowser
                                files={files}
                                folderChain={folderChain}
                                onFileAction={handleFileAction}
                                fileActions={myFileActions}
                                disableDefaultFileActions={actionsToDisable}
                                iconComponent={MyIcon}

                            >
                                <FileToolbar />
                                <FileNavbar />
                                <FileList />
                                <FileContextMenu />
                            </FileBrowser>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default DocumentFileBrowser;

