import React from "react";
import { FieldValues, UseControllerProps, useController } from "react-hook-form";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import classNames from "classnames";

const HeadlessUIRadioGroupControl = (props: UseControllerProps<FieldValues, string> & { options: { id: string, name: string }[] }) => {

    const {
        field: { value, onChange }
    } = useController(props);

    return (
        <RadioGroup className="mt-2 cursor-pointer" defaultValue={value} onChange={onChange}>
            <div className="grid grid-cols-3 gap-3 ">
                {props.options.map((option) => (
                    <Radio
                        key={option.id}
                        value={option.id}
                        className={
                            classNames(
                                'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                'data-[checked]:bg-rhinoBlue-600 data-[checked]:border-transparent data-[checked]:text-white data-[checked]:hover:bg-rhinoBlue-700',
                                'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 ',
                            )
                        }
                    >
                        <Label as="div">{option.name}</Label>
                    </Radio>
                ))}
            </div>
        </RadioGroup >

    )
}


export const HeadlessUIRadioGroup = (props: {
    value: string, onChange: React.Dispatch<React.SetStateAction<string>>, options: { id: string, name: string }[]
}) => {
    const { value, onChange, options } = props;
    const rows = options.length;



    return (
        <RadioGroup className="mt-2 cursor-pointer" defaultValue={value} onChange={onChange}>
            <div className={classNames(
                "grid gap-3 ",
                { 'grid-cols-2': rows === 2 },
                { 'grid-cols-3': rows % 3 === 0 },
                { 'grid-cols-4': rows % 4 === 0 },
                { 'grid-cols-5': rows >= 5 },
            )}>
                {props.options.map((option) => (
                    <Radio
                        key={option.id}
                        value={option.id}
                        className={
                            classNames(
                                'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                'data-[checked]:bg-rhinoBlue-600 data-[checked]:border-transparent data-[checked]:text-white data-[checked]:hover:bg-rhinoBlue-700',
                                'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 ',
                            )
                        }
                    >
                        <Label as="div">{option.name}</Label>
                    </Radio>
                ))}
            </div>
        </RadioGroup>

    )
}

export default HeadlessUIRadioGroupControl