import React from "react";
import SignaturePad from "react-signature-pad-wrapper";
import axios from "axios";
import Swal from "sweetalert2";
import { myToast, urls } from "../../common";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faBiohazard, faSignatureLock, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { PropsDeclaration } from "./types";

export const declarationStatusColour = (str: string) => {
    return str === 'signed' ? 'bg-green-500' :
        str === 'sent' ? 'bg-orange-500' :
            str === 'open' ? 'bg-red-500' :
                'bg-gray-500';
}


export const handleSignDeclaration = (id: string, signatureRef: SignaturePad, data: PropsDeclaration, cb?: Function) => {
    if (signatureRef.isEmpty()) {
        myToast({ title: 'Error', 'message': 'Please sign before submitting', colour: 'red', icon: faBiohazard })
        return;
    }

    if (!data.signature_name?.trim() || !data.signature_position?.trim()) {
        myToast({ title: 'Error', 'message': 'Please fill in name and position', colour: 'red', icon: faBiohazard })
        return;
    }

    Swal.fire({
        title: 'Are you sure?',
        text: 'You are about sign and confirm this declaration!',
        iconHtml: icon(faSignatureLock).html.join(''),
        customClass: {
            icon: 'border-0 text-green-600'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm & sign!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.put(`${urls.remoteURL}declaration/${id}/sign`, { ...data, signature: signatureRef.toDataURL() })
                .then(() => {
                    if (cb) { cb(); }
                    myToast({ title: 'Signed', colour: 'green', icon: faThumbsUp })
                })
                .catch((error) => {
                    myToast({ title: 'Error', 'message': error.response.data.message || 'Error signing declaration?', colour: 'red', icon: faBiohazard })
                });

        }
    })
}

